import { NgModule } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MarkdownModule } from 'ngx-markdown';
import { ThkeeCommonComponent } from './components/thkee-common.component';
import { ProcessingDirective } from './directives/processing.directive';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FormlySetup, VideoPlayerComponent } from './components';
import { AccordionComponent } from './components/accordion/accordion.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { CopierComponent } from './components/copier/copier.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { FormlyDateRangePickerComponent } from './components/formly/types/date-range-picker/formly-date-range-picker.component';
import { FormlyDatepickerComponent } from './components/formly/types/datepicker/datepicker.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { DropdownItemDirective } from './directives/dropdown/dropdown-item.directive';
import { DropdownMenuDirective } from './directives/dropdown/dropdown-menu.directive';
import { DropdownToggleDirective } from './directives/dropdown/dropdown-toggle.directive';
import { DropdownDirective } from './directives/dropdown/dropdown.directive';
import { NestedCheckboxChildDirective } from './directives/nested-checkbox-child.directive';
import { NestedCheckboxDirective } from './directives/nested-checkbox.directive';
import { ScrollLoadMoreDirective } from './directives/scroll-load-more.directive';
import { ScrollTrackerDirective } from './directives/scroll-tracker.directive';
import {
  CourseFilterPipe,
  formatDatePipe,
  formatDurationHourPipe,
  formatDurationPipe,
  IndexToAlphabetPipe,
  PricePipe,
  TimeAgoPipe,
  TruncatePipe,
} from './pipes/';
import { ActorFullnamePipe } from './pipes/actor-fullname.pipe';

const commomnPipes = [
  PricePipe,
  formatDatePipe,
  TimeAgoPipe,
  formatDurationPipe,
  IndexToAlphabetPipe,
  TruncatePipe,
  CourseFilterPipe,
  ActorFullnamePipe,
  formatDurationHourPipe,
];

@NgModule({
  declarations: [
    commomnPipes,

    ThkeeCommonComponent,
    NotificationsComponent,
    AvatarComponent,
    CopierComponent,
    PaginationComponent,
    TabsComponent,
    AccordionComponent,

    ProcessingDirective,
    DropdownDirective,
    DropdownToggleDirective,
    DropdownMenuDirective,
    DropdownItemDirective,
    VideoPlayerComponent,
    NestedCheckboxDirective,
    NestedCheckboxChildDirective,
    ScrollTrackerDirective,
    ScrollLoadMoreDirective,
    DatepickerComponent,
    DateRangePickerComponent,
    FormlyDatepickerComponent,
    FormlyDateRangePickerComponent,
  ],
  imports: [
    MarkdownModule.forRoot(),
    AngularSvgIconModule.forRoot(),
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FormlySetup,
  ],
  providers: [],
  exports: [
    commomnPipes,

    ThkeeCommonComponent,
    NotificationsComponent,
    AvatarComponent,
    CopierComponent,
    PaginationComponent,
    TabsComponent,
    AccordionComponent,
    DatepickerComponent,
    DateRangePickerComponent,

    ProcessingDirective,
    DropdownDirective,
    DropdownToggleDirective,
    DropdownMenuDirective,
    DropdownItemDirective,
    VideoPlayerComponent,
    NestedCheckboxDirective,
    NestedCheckboxChildDirective,
    ScrollTrackerDirective,
    ScrollLoadMoreDirective,
  ],
})
export class ThkeeCommonModule {}
